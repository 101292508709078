/**
 * Import JS knihoven
 * ts-ignore musi byt pridany, kedze sa jedna o JS knihovny bez zadefinovanych *.d.ts suborov pre Typescript.
 * V priprade chybneho importu JS knihovny, sa nezobrazi chyba tu, ale pri generovani vysledneho JS webpackom.
 */
//@ts-ignore
import applepay from '@sportisimo/old_applepay/src/index';
//@ts-ignore
import autocomplete from '@sportisimo/old_autocomplete/src/index';
//@ts-ignore
import anchor from '@sportisimo/old_anchor/src/index';
//@ts-ignore
import banner from '@sportisimo/old_banner/src/index';
//@ts-ignore
import bccamera from '@sportisimo/old_bc-camera/src/index';
//@ts-ignore
import bcscan from '@sportisimo/old_bc-scan/src/index';
//@ts-ignore
import bg from '@sportisimo/old_bg/src/index';
//@ts-ignore
import countdown from '@sportisimo/old_countdown/src/index';
//@ts-ignore
import click from '@sportisimo/old_click/src/index';
//@ts-ignore
import easing from '@sportisimo/old_easing/src/index';
//@ts-ignore
import fb from '@sportisimo/old_fb/src/index';
//@ts-ignore
import form from '@sportisimo/old_form/src/index';
//@ts-ignore
import geolocate from '@sportisimo/old_geolocate/src/index';
//@ts-ignore
import gtm from '@sportisimo/old_gtm/src/index';
//@ts-ignore
import height from '@sportisimo/old_height/src/index';
//@ts-ignore
import checkbox from '@sportisimo/old_checkbox/src/index';
//@ts-ignore
import input from '@sportisimo/old_input/src/index';
//@ts-ignore
import image from '@sportisimo/old_image/src/index';
//@ts-ignore
import imagerotator from '@sportisimo/old_image-rotator/src/index';
//@ts-ignore
import loading from '@sportisimo/old_loading/src/index';
//@ts-ignore
import link from '@sportisimo/old_link/src/index';
//@ts-ignore
import menu from '@sportisimo/old_menu/src/index';
//@ts-ignore
import overlay from '@sportisimo/old_overlay/src/index';
//@ts-ignore
import productlist from '@sportisimo/old_product-list/src/index';
//@ts-ignore
import robotcheck from '@sportisimo/old_robotcheck/src/index';
//@ts-ignore
import rwd from '@sportisimo/old_rwd/src/index';
//@ts-ignore
import scroll from '@sportisimo/old_scroll/src/index';
//@ts-ignore
import serviceworker from '@sportisimo/old_service-worker/src/index';
//@ts-ignore
import share from '@sportisimo/old_share/src/index';
//@ts-ignore
import sound from '@sportisimo/old_sound/src/index';
//@ts-ignore
import slider from '@sportisimo/old_slider/src/index';
//@ts-ignore
import touch from '@sportisimo/old_touch/src/index';
//@ts-ignore
import upload from '@sportisimo/old_upload/src/index';
//@ts-ignore
import url from '@sportisimo/old_url/src/index';
//@ts-ignore
import valueplusminus from '@sportisimo/old_value-plus-minus/src/index';
//@ts-ignore
import valueselect from '@sportisimo/old_value-select/src/index';
//@ts-ignore
import valueslider from '@sportisimo/old_value-slider/src/index';
//@ts-ignore
import valuetoggle from '@sportisimo/old_value-toggle/src/index';
//@ts-ignore
import viewport from '@sportisimo/old_viewport/src/index';
//@ts-ignore
import yvideo from '@sportisimo/old_yvideo/src/index';

/**
 * Import TS core services
 */
import { Sportisimo_Model_Services_DirectionDataService as DirectionDataService } from '@sportisimo/sportisimo/src/Model/Services/DirectionDataService';
import { Sportisimo_Model_Services_LogService as LogService } from '@sportisimo/sportisimo/src/Model/Services/LogService';
import { Sportisimo_Model_Services_NoLogService as NoLogService } from '@sportisimo/sportisimo/src/Model/Services/NoLogService';

/**
 * Import TS services
 */
import { Sportisimo_Button_Model_Services_ButtonDataService as ButtonDataService } from '@sportisimo/button/src/Model/Services/ButtonDataService';
import { Sportisimo_Loading_Model_Services_LoadingDataService as LoadingDataService } from '@sportisimo/sportisimo_loading/src/Model/Services/LoadingDataService';
import { Sportisimo_Overlay_Model_Services_OverlayDataService as OverlayDataService } from '@sportisimo/sportisimo_overlay/src/Model/Services/OverlayDataService';
import { Sportisimo_Sound_Model_Services_SoundDataService as SoundDataService } from '@sportisimo/sportisimo_sound/src/Model/Services/SoundDataService';
import { Sportisimo_Touch_Model_Services_TouchDataService as TouchDataService } from '@sportisimo/sportisimo_touch/src/Model/Services/TouchDataService';
import { Sportisimo_Trigger_Model_Services_TriggerDataService as TriggerDataService } from '@sportisimo/sportisimo_trigger/src/Model/Services/TriggerDataService';
import { Sportisimo_Model_Services_UrlDataService as UrlDataService } from '@sportisimo/core/src/Model/Services/UrlDataService';
import { Sportisimo_Zoe_Model_Services_ZoeAddToCartService as ZoeAddToCartService } from '@sportisimo/zoe/src/Model/Services/ZoeAddToCartService';
import { Sportisimo_Zoe_Model_Services_ZoeAddToCartDataService as ZoeAddToCartDataService } from '@sportisimo/zoe/src/Model/Services/ZoeAddToCartDataService';
import { Sportisimo_Zoe_Model_Services_ZoeCartContentService as ZoeCartContentService } from '@sportisimo/zoe/src/Model/Services/ZoeCartContentService';
import { Sportisimo_Zoe_Model_Services_ZoeCartContentDataService as ZoeCartContentDataService } from '@sportisimo/zoe/src/Model/Services/ZoeCartContentDataService';
import { Sportisimo_Zoe_Model_Services_ZoeCartContentItemDataService as ZoeCartContentItemDataService } from '@sportisimo/zoe/src/Model/Services/ZoeCartContentItemDataService';
import { Sportisimo_Collection_Model_Services_CollectionService as CollectionService } from '@sportisimo/collection/src/Model/Services/CollectionService';
import { Sportisimo_Collection_Model_Services_CollectionDataService as CollectionDataService } from '@sportisimo/collection/src/Model/Services/CollectionDataService';
import { Sportisimo_Collection_Model_Services_SearchFilterDataService as SearchFilterDataService } from '@sportisimo/collection/src/Model/Services/SearchFilterDataService';
import { Sportisimo_Collection_Model_Services_SearchFilterService as SearchFilterService } from '@sportisimo/collection/src/Model/Services/SearchFilterService';
import { Sportisimo_Collection_Model_Services_QuickSearchFilterDataService as QuickSearchFilterDataService } from '@sportisimo/collection/src/Model/Services/QuickSearchFilterDataService';
import { Sportisimo_Collection_Model_Services_QuickSearchFilterService as QuickSearchFilterService } from '@sportisimo/collection/src/Model/Services/QuickSearchFilterService';
import { Sportisimo_Collection_Model_Services_ItemDataService as ItemDataService } from '@sportisimo/collection/src/Model/Services/ItemDataService';
import { Sportisimo_Collection_Model_Services_ItemService as ItemService } from '@sportisimo/collection/src/Model/Services/ItemService';
import { Sportisimo_Collection_Model_Services_ItemGroupDataService as ItemGroupDataService } from '@sportisimo/collection/src/Model/Services/ItemGroupDataService';
import { Sportisimo_Collection_Model_Services_ItemGroupService as ItemGroupService } from '@sportisimo/collection/src/Model/Services/ItemGroupService';
import { Sportisimo_Collection_Model_Services_RangeService as RangeService } from '@sportisimo/collection/src/Model/Services/RangeService';
import { Sportisimo_ScrollNavigation_Model_Services_BaseDataService as ScrollNavigationBaseDataService } from '@sportisimo/scroll-navigation/src/Model/Services/BaseDataService';
import { Sportisimo_ScrollNavigation_Model_Services_NavigationItemDataService as ScrollNavigationItemDataService } from '@sportisimo/scroll-navigation/src/Model/Services/NavigationItemDataService';
import { Sportisimo_ScrollNavigation_Model_Services_ScrollNavigationService as ScrollNavigationService } from '@sportisimo/scroll-navigation/src/Model/Services/ScrollNavigationService';

/**
 * Import TS factories
 */
import { Sportisimo_Loading_Model_Factories_LoadingFactory as LoadingFactory } from '@sportisimo/sportisimo_loading/src/Model/Factories/LoadingFactory';
import { Sportisimo_Overlay_Model_Factories_OverlayFactory as OverlayFactory } from '@sportisimo/sportisimo_overlay/src/Model/Factories/OverlayFactory';
import { Sportisimo_Sound_Model_Factories_SoundFactory as SoundFactory } from '@sportisimo/sportisimo_sound/src/Model/Factories/SoundFactory';
import { Sportisimo_Trigger_Model_Factories_TriggerFactory as TriggerFactory } from '@sportisimo/sportisimo_trigger/src/Model/Factories/TriggerFactory';

/**
 * Import TS knihoven
 */
import { Sportisimo_Button_Button as Button } from '@sportisimo/button/src/Button';
import { Sportisimo_Popup as Popup } from '@sportisimo/popup/src/Popup';
import { Sportisimo_Utils_UrlUtils as UrlUtils } from '@sportisimo/utils/src/UrlUtils/UrlUtils';
import { Sportisimo_HistoryNavigation as HistoryNavigation } from '@sportisimo/history-navigation/src/HistoryNavigation';
import { Sportisimo_Zoe_Zoe as Zoe } from '@sportisimo/zoe/src/Zoe';
import { Sportisimo_Collection_Collection as Collection } from '@sportisimo/collection/src/Collection';
import { Sportisimo_ScrollNavigation_ScrollNavigation as ScrollNavigation } from '@sportisimo/scroll-navigation/src/ScrollNavigation';

/**
 * Inicializacia TS core services
 * Pokud je v localStorage nastaveno sm_log_enabled na '1', tak se inicializuje LogService, inak se inicializuje NoLogService.
 * Je to pomocna servisa na logovanie informacii do konzoly prehliadaca.
 */
const logEnabled: string | null = localStorage.getItem('sm_log_enabled');
const logService: LogService | NoLogService = (logEnabled && logEnabled === '1') ? new LogService(true, true, true, true) : new NoLogService();
const directionDataService: DirectionDataService = new DirectionDataService(logService);

/**
 * Inicializacia TS factories
 */
const loadingFactory: LoadingFactory = new LoadingFactory(logService);
const overlayFactory: OverlayFactory = new OverlayFactory(logService);
const soundFactory: SoundFactory = new SoundFactory(logService);
const triggerFactory: TriggerFactory = new TriggerFactory(logService);

/**
 * Inicializacia TS services
 */
const loadingDataService: LoadingDataService = new LoadingDataService(logService);
const overlayDataService: OverlayDataService = new OverlayDataService(logService);
const soundDataService: SoundDataService = new SoundDataService(logService);
const triggerDataService: TriggerDataService = new TriggerDataService(logService);
const urlDataService: UrlDataService = new UrlDataService(logService);
const touchDataService: TouchDataService = new TouchDataService(logService, directionDataService);
const buttonDataService: ButtonDataService = new ButtonDataService(logService, triggerDataService, soundDataService, loadingDataService, overlayDataService, touchDataService);
const zoeCartContentItemDataService: ZoeCartContentItemDataService = new ZoeCartContentItemDataService(logService);
const zoeCartContentDataService: ZoeCartContentDataService = new ZoeCartContentDataService(logService, zoeCartContentItemDataService);
const zoeAddToCartDataService: ZoeAddToCartDataService = new ZoeAddToCartDataService(logService, urlDataService);
const zoeCartContentService: ZoeCartContentService = new ZoeCartContentService(logService, zoeCartContentDataService);
const zoeAddToCartService: ZoeAddToCartService = new ZoeAddToCartService(logService, zoeAddToCartDataService);
const collectionDataService: CollectionDataService = new CollectionDataService(logService, triggerDataService, loadingDataService);
const searchFilterDataService: SearchFilterDataService = new SearchFilterDataService(logService);
const quickSearchFilterDataService: QuickSearchFilterDataService = new QuickSearchFilterDataService(logService);
const itemDataService: ItemDataService = new ItemDataService(logService);
const itemGroupDataService: ItemGroupDataService = new ItemGroupDataService(logService);
const itemService: ItemService = new ItemService(logService, itemDataService);
const itemGroupService: ItemGroupService = new ItemGroupService(logService, itemGroupDataService);
const rangeService: RangeService = new RangeService(logService);
const collectionService: CollectionService = new CollectionService(
  logService,
  triggerFactory,
  loadingFactory,
  collectionDataService,
  itemService,
  itemGroupService,
  rangeService
);
const quickSearchFilterService: QuickSearchFilterService = new QuickSearchFilterService(
  logService,
  quickSearchFilterDataService,
  collectionService,
  itemService
);
const searchFilterService: SearchFilterService = new SearchFilterService(
  logService,
  searchFilterDataService,
  collectionService,
  itemService
);
const scrollNavigationItemDataService: ScrollNavigationItemDataService = new ScrollNavigationItemDataService(
  logService,
  touchDataService
);
const scrollNavigationBaseDataService: ScrollNavigationBaseDataService = new ScrollNavigationBaseDataService(
  logService,
  scrollNavigationItemDataService
);
const scrollNavigationService: ScrollNavigationService = new ScrollNavigationService(
  logService,
  scrollNavigationBaseDataService
);

/**
 * Zadefinovanie sm objektu do window
 */
declare global
{
  interface Window
  {
    sm: any
  }
}

const sm: any = window.sm;

/**
 * Zadefinovanie JS knihoven do window.sm objektu
 */
applepay(sm);
autocomplete(sm);
anchor(sm);
banner(sm);
bccamera(sm);
bcscan(sm);
bg(sm);
countdown(sm);
click(sm);
easing(sm);
fb(sm);
form(sm);
geolocate(sm);
gtm(sm);
height(sm);
checkbox(sm);
input(sm);
image(sm);
imagerotator(sm);
link(sm);
loading(sm);
menu(sm);
overlay(sm);
productlist(sm);
robotcheck(sm);
rwd(sm);
scroll(sm);
serviceworker(sm);
share(sm);
sound(sm);
slider(sm);
touch(sm);
upload(sm);
url(sm);
valueplusminus(sm);
valueselect(sm);
valueslider(sm);
valuetoggle(sm);
viewport(sm);
yvideo(sm);

/**
 * Inicializacia JS knihoven
 */
sm.rwd.init();
sm.height.init();
sm.bg.init();
sm.image.init();
sm.banner.init();
sm.slider.init();
sm.menu.init();
sm.anchor.init();
sm.scroll.init();
sm.click.init();
sm.form.init();
sm.link.init();

sm.autocomplete.init();
sm.input.init();
sm.valueselect.init();
sm.valueslider.init();
sm.valuetoggle.init();
sm.valueplusminus.init();
sm.checkbox.init();
sm.productlist.init();
sm.countdown.init();
sm.geolocate.init();
sm.upload.init();
sm.share.init();
sm.applepay.init();
sm.imagerotator.init();
sm.yvideo.init();
sm.bccamera.init();

/**
 * Inicializacia TS knihoven
 */
sm.utils = {};
sm.utils.urlUtils = new UrlUtils(window);

sm.button = new Button(
  logService,
  buttonDataService,
  loadingFactory,
  overlayFactory,
  soundFactory,
  triggerFactory,
  sm.touch ? sm.touch : null
);
sm.button.init();

sm.historyNavigation = new HistoryNavigation(window, sm.utils.urlUtils ? sm.utils.urlUtils : null);
sm.historyNavigation.init();

sm.popup = new Popup(
  sm.utils.urlUtils ? sm.utils.urlUtils : null,
  sm.rwd ? sm.rwd : null,
  sm.touch ? sm.touch : null,
  sm.overlay ? sm.overlay : null
);
sm.popup.init();

sm.zoe = new Zoe(zoeAddToCartService, zoeCartContentService);
sm.zoe.init();

sm.collection = new Collection(
  collectionService,
  searchFilterService,
  quickSearchFilterService,
  itemService,
  itemGroupService
);
sm.collection.init();

sm.scrollNavigation = new ScrollNavigation(scrollNavigationService, sm.touch ? sm.touch : null);
sm.scrollNavigation.init();

/**
 * Inicializacia service workera
 */
if(sm.initData && sm.initData.serviceWorkerInit)
{
  sm.serviceworker.init();
}

/**
 * Inicializacia GTM
 */
if(sm.initData && sm.initData.gtmInit)
{
  if(sm.initData.gtmSetId)
  {
    sm.gtm.setId(sm.initData.gtmSetId);
  }
  sm.gtm.init();
}

/**
 * Inicializacia scriptov na evente window load
 * Vykona sa ked je nacitana cela stranka, vratane CSS, obrazkov a ostatnych assetov
 */
window.addEventListener('load', () =>
{
  sm.robotcheck.init();
});

/**
 * Zadefinovanie typu globalnej konstatny, pre vlozenie informacii o verziach pouzitych
 * modulov pomocou webpack.DefinePlugin, a vlozenie tychto dat do sm objektu
 */
declare const __MODULES_INFO__: string;
sm._modules = __MODULES_INFO__;
